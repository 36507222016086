import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly user$ = new BehaviorSubject<User | null>(null);
  private readonly isLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly apiService: ApiService) {
    const userData =
      window.localStorage.getItem('user') ||
      (environment.isDemo ? '{"authToken":"demo-user"}' : null);

    if (userData) {
      try {
        const user = JSON.parse(userData);

        if (!user?.authToken) {
          throw '';
        }

        this.apiService.getUser(user.authToken).subscribe(
          (user) => {
            if (user) {
              this.setUser(user);
            }

            this.isLoaded$.next(true);
          },
          (error) => {
            console.error(error);

            this.isLoaded$.next(true);
          }
        );
      } catch (e) {
        this.logout();
        this.isLoaded$.next(true);
      }
    } else {
      this.isLoaded$.next(true);
    }
  }

  get isUserLoaded$(): Observable<boolean> {
    return this.isLoaded$;
  }

  getUser(): Observable<User | null> {
    return this.user$.asObservable();
  }

  getUserAsync(): User | null {
    return this.user$.value;
  }

  setUser(user: User) {
    this.user$.next(user);
    window.localStorage.setItem('user', JSON.stringify(user));
  }

  logout() {
    this.user$.next(null);
    window.localStorage.removeItem('user');
  }
}
