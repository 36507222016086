import { WbField } from 'src/app/models/wb-fields';
import { Item } from '../../../models/item';

export interface SortItem {
  title: string;
  field: WbField;
  asc: boolean;
}

export const sortedItems: SortItem[] = [
  { title: 'По добавлению', field: WbField.messageDate, asc: false },
  { title: 'По популярности', field: WbField.messageRepeatCount, asc: true },
  { title: 'По цене', field: WbField.detailSalePriceU, asc: true },
];

export class FilterSort {
  filter(items: Item[], selectedItem?: SortItem): Item[] {
    if (!selectedItem || !items?.length) {
      return items;
    }

    return items.sort((item1, item2) => {
      const result =
        item1[selectedItem.field]! > item2[selectedItem.field]! ? 1 : -1;

      return result * (selectedItem.asc ? 1 : -1);
    });
  }
}
