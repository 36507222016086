<tui-root>
  <div class="main">
    <app-header
      *ngIf="isLogined$ | async"
      class="tui-space_bottom-5"
    ></app-header>
    <router-outlet></router-outlet>
  </div>

  <!--
       If you need, you can add something between Taiga portal layers:
      -->
  <ng-container ngProjectAs="tuiOverContent">
    <!-- Content over app content -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverDialogs">
    <!-- Content over dialogs -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverAlerts">
    <!-- Content over notifications -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverPortals">
    <!-- Content over dropdowns -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverHints">
    <!-- Content over hints -->
  </ng-container>
</tui-root>
