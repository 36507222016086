import { Injectable } from '@angular/core';
import { map, Observable, of, shareReplay, tap } from 'rxjs';
import { Item } from '../models/item';
import { WbField } from '../models/wb-fields';
import { ApiService } from './api.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  private readonly cache = new Map();

  getItems(sheetName: string): Observable<Item[]> {
    if (this.cache.has(sheetName)) {
      return of(this.cache.get(sheetName));
    }

    return this.api
      .getAllSheetItems(sheetName, this.userService.getUserAsync()?.authToken)
      .pipe(
        tap((items) => {
          this.cache.set(sheetName, items);
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
  }

  getFilteredItems(sheetName: string): Observable<Item[]> {
    return this.getItems(sheetName).pipe(
      map((items) => {
        const itemsMap = new Map();
        const result: Item[] = [];

        for (let item of items) {
          let repeated = itemsMap.get(item[WbField.imtId]);

          if (!repeated) {
            repeated = [];
            itemsMap.set(item[WbField.imtId], repeated);

            result.push({ ...item, [WbField.frontRepeatedList]: repeated });
          }

          repeated.push(item);

          itemsMap.set(item[WbField.imtId], repeated);
        }

        return result;
      })
    );
  }

  getProductsPhotoByProductId(
    sheetName: string
  ): Observable<{ [key: string]: string }> {
    return this.api
      .getProductsPhotos(sheetName, this.userService.getUserAsync()?.authToken)
      .pipe(
        map((products) =>
          products.reduce(
            (res, v) => ({ ...res, [v[WbField.imtId]]: v.photoUrl }),
            {}
          )
        )
      );
  }

  constructor(
    private readonly api: ApiService,
    private readonly userService: UserService
  ) {}
}
