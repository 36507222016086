import { WbField } from 'src/app/models/wb-fields';
import { Item } from '../../../models/item';

export interface FilterCategoryData {
  items: string[];
}

export class FilterCategory {
  readonly data: FilterCategoryData;

  constructor(data: FilterCategoryData) {
    this.data = data;
  }

  filter(items: Item[], selectedCategories?: string[]): Item[] {
    if (!selectedCategories?.length) {
      return items;
    }

    const map = new Set(selectedCategories);

    return items.filter((item) => map.has(item[WbField.subjRootName]));
  }
}
