import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, switchMap, take } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent {
  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly apiService: ApiService
  ) {}

  onLogout() {
    if (environment.isDemo) {
      window.open('https://vid-sverhu.ru', '_self');

      return;
    }

    this.userService
      .getUser()
      .pipe(
        switchMap((user) => this.apiService.logout(user!.authToken)),
        take(1),
        finalize(() => {
          this.userService.logout();
          this.router.navigate(['/']);
        })
      )
      .subscribe();
  }
}
