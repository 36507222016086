export enum WbField {
  imtId = 'imt_id',
  subjRootName = 'subj_root_name',
  subjName = 'subj_name',
  detailName = 'detail.name',
  season = 'season',
  imtName = 'imt_name',
  messageSiteUrl = 'message.siteUrl',
  detailSalePriceU = 'detail.salePriceU',
  nmId = 'nm_id',
  sellingBrandName = 'selling.brand_name',
  kinds = 'kinds',
  messageText = 'message.text',
  messageUrl = 'message.url',
  messageRepeatCount = 'message.repeatCount',
  messageDate = 'message.date',
  messageForwardDate = 'message.forwardDate',
  frontRepeatedList = 'frontRepeatedList',
  detailMaxSize = 'detail.maxSize',
}
