import { Directive, ElementRef, Inject, Optional, Output } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { TUI_SCROLL_REF } from '@taiga-ui/core';
import { fromEvent, merge } from 'rxjs';
import { auditTime, filter, startWith } from 'rxjs/operators';

const DEFAULT_DISTANCE_SCREENS = 1;

@Directive({
  selector: '[appInfinityScroll]',
})
export class InfinityScrollDirective {
  @Output()
  readonly scrollingFinished = merge(
    fromEvent(this.scrollRef?.nativeElement || this.window, 'scroll'),
    fromEvent(this.window, 'resize')
  ).pipe(
    startWith(null),
    auditTime(200),
    filter(() => {
      const windowHeight = this.window.innerHeight;
      const { bottom: distanceFromScreenTopToElementBottom } =
        this.elementRef.nativeElement.getBoundingClientRect();
      const scrollDistanceToElementBottom =
        Math.abs(distanceFromScreenTopToElementBottom - windowHeight) /
        windowHeight;

      return scrollDistanceToElementBottom <= DEFAULT_DISTANCE_SCREENS;
    })
  );

  constructor(
    @Inject(WINDOW)
    private readonly window: Window,
    private readonly elementRef: ElementRef,
    @Optional()
    @Inject(TUI_SCROLL_REF)
    private readonly scrollRef?: ElementRef<HTMLElement>
  ) {}
}
