import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiMoneyModule } from '@taiga-ui/addon-commerce';
import { TuiPreviewModule } from '@taiga-ui/addon-preview';
import { TuiLetModule, TuiMapperPipeModule } from '@taiga-ui/cdk';
import { TuiButtonModule, TuiLinkModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiCarouselModule } from '@taiga-ui/kit';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { ItemDialogComponent } from './item-dialog.component';

@NgModule({
  declarations: [ItemDialogComponent],
  exports: [ItemDialogComponent],
  imports: [
    CommonModule,
    TuiMapperPipeModule,
    TuiMoneyModule,
    TuiLetModule,
    PolymorpheusModule,
    TuiButtonModule,
    TuiSvgModule,
    TuiCarouselModule,
    TuiPreviewModule,
    TuiLinkModule,
  ],
})
export class ItemDialogModule {}
