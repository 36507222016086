/**
 * Функция возвращает правильную форму слова в зависимости от числа
 * @param count цифра, которую нужно привести к нужному склонению
 * @param words Слова для цифр 1, 2, 3 [упоминание, упоминания, упоминаний]
 * @returns
 */
export function pluralize(count: number, words: string[]) {
  var cases = [2, 0, 1, 1, 1, 2];
  return words[
    count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]
  ];
}
