import { WbField } from 'src/app/models/wb-fields';
import { Item } from '../../../models/item';

export class FilterPriceTo {
  filter(items: Item[], priceTo?: string | number): Item[] {
    if (!priceTo) {
      return items;
    }

    return items.filter((item) => item[WbField.detailSalePriceU] <= priceTo);
  }
}
