import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import {
  BehaviorSubject,
  EMPTY,
  ReplaySubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ContextDialog } from 'src/app/components/item-dialog/context-dialog';
import { ItemDialogComponent } from 'src/app/components/item-dialog/item-dialog.component';
import { pluralize } from 'src/app/utils/plural';
import { Item } from '../../models/item';
import { LogicField } from '../../models/logic-fields';
import { WbField } from '../../models/wb-fields';
import { ItemsService } from '../../services/items.service';

const QUERY_PARAM_PRODUCT_ID = 'productId';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class HomeComponent {
  readonly allItems$ = this.itemsService
    .getFilteredItems((this.activatedRoute.snapshot.data || {})['sheetName'])
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  readonly items$ = new ReplaySubject<Item[]>(1);
  readonly showCount$ = new BehaviorSubject<number>(50);
  readonly loaded$ = this.allItems$.pipe(
    map(() => true),
    startWith(false)
  );
  readonly slicedItems$ = combineLatest([this.items$, this.showCount$]).pipe(
    debounceTime(0),
    map(([items, count]) => items?.slice(0, count))
  );
  readonly productsPhotos$ = this.itemsService
    .getProductsPhotoByProductId(
      (this.activatedRoute.snapshot.data || {})['sheetName']
    )
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  readonly fields: Array<LogicField> = [
    LogicField.sort,
    LogicField.category,
    LogicField.priceFrom,
    LogicField.priceTo,
    LogicField.search,
  ];

  readonly getCategories = (items: Item[] | null): string[] => {
    return items?.map((item) => item[WbField.subjRootName]) || [];
  };

  readonly getCategory = (item: Item): string => item[WbField.subjRootName];
  readonly getPrice = (item: Item): number | null =>
    item[WbField.detailSalePriceU] || null;
  readonly getDescription = (item: Item): string => item[WbField.imtName];
  readonly getCommentHint = (count: number): string =>
    `${count} ${pluralize(count, ['упоминание', 'упоминания', 'упоминаний'])}`;
  readonly getWbUrl = (item: Item): string => item[WbField.messageSiteUrl];
  readonly getCommentCount = (item: Item): number =>
    item[WbField.messageRepeatCount] > 1 ? item[WbField.messageRepeatCount] : 0;
  readonly trackItems = (item: Item) => item[WbField.imtId];
  readonly getWbProductPhotoUrl = (
    item: Item,
    productsPhotos: { [key: string]: string }
  ): string =>
    (item && productsPhotos && productsPhotos[item[WbField.imtId]]) || '';
  readonly productItemQueryParams = (item: Item): Params => ({
    ...this.activatedRoute.snapshot.queryParams,
    [QUERY_PARAM_PRODUCT_ID]: item[WbField.imtId],
  });
  readonly getShoesMaxSize = (item: Item): string | null =>
    (item[WbField.subjRootName] === 'Обувь' && item[WbField.detailMaxSize]) ||
    null;

  constructor(
    public readonly activatedRoute: ActivatedRoute,
    private readonly itemsService: ItemsService,
    private readonly router: Router,
    private readonly destroy$: TuiDestroyService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector
  ) {
    combineLatest([
      this.items$,
      this.activatedRoute.queryParamMap.pipe(
        map((queryParamMap) => queryParamMap.get(QUERY_PARAM_PRODUCT_ID)),
        distinctUntilChanged()
      ),
      this.productsPhotos$,
    ])
      .pipe(
        filter(([items, _, photos]) => Boolean(items && photos)),
        switchMap(([items, productId, photos]) => {
          if (!productId) {
            return EMPTY;
          }

          const item = items.find(
            (item) => String(item[WbField.imtId]) === productId
          );

          if (!item) {
            Sentry.captureException(
              new Error(`Open product not found - ${productId}`)
            );

            return EMPTY;
          }

          return this.dialogService
            .open<ContextDialog>(
              new PolymorpheusComponent(ItemDialogComponent, this.injector),
              {
                data: { item, photoUrl: photos && photos[item[WbField.imtId]] },
              }
            )
            .pipe(
              finalize(() => {
                if (
                  this.activatedRoute.snapshot.queryParamMap.get(
                    QUERY_PARAM_PRODUCT_ID
                  )
                ) {
                  this.router.navigate([], {
                    relativeTo: this.activatedRoute,
                    replaceUrl: false,
                    queryParams: {
                      ...this.activatedRoute.snapshot.queryParams,
                      [QUERY_PARAM_PRODUCT_ID]: '',
                    },
                  });
                }
              })
            );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onFilteredItems(items: Item[]) {
    this.items$.next(items);
    this.showCount$.next(50);
  }

  onScroll() {
    this.showCount$.next(this.showCount$.value + 50);
  }
}
