<div class="tui-container tui-container_adaptive container">
  <div class="logo">
    <img src="assets/logo.jpg" alt="" />
    <span class="text tui-text_h5">Вид&nbsp;сверху</span>
  </div>
  <form>
    <tui-loader [showLoader]="!!(userLoading$ | async)"> </tui-loader>
    <div class="tui-form__row">
      <tui-notification>
        При нажатии на кнопку входа, откроется телеграм чат с ботом. Необходимо
        нажать start для входа.
      </tui-notification>
    </div>
    <div class="tui-form__row buttons">
      <button tuiButton type="button" class="btn" (click)="onSignIn()">
        Войти через телеграм
      </button>
      <a
        tuiButton
        type="button"
        appearance="secondary"
        class="btn"
        href="https://demo.vid-sverhu.ru"
      >
        Попробовать демо версию
      </a>
    </div>
  </form>
</div>
