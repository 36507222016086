<form *ngIf="fields$ | async as fields" class="wrapper" [formGroup]="form">
  <tui-multi-select
    *ngIf="enabledFilter(fields, LogicField.category)"
    class="categories"
    formControlName="category"
    [editable]="false"
    [tuiTextfieldCleaner]="true"
  >
    Категория
    <tui-data-list-wrapper
      *tuiDataList
      tuiMultiSelectGroup
      [items]="categories$ | async"
    ></tui-data-list-wrapper>
  </tui-multi-select>

  <tui-select
    *tuiLet="sortSelectedItem$ | async | tuiMapper: sortItem as item"
    class="sort"
    formControlName="sort"
    [valueContent]="sortSelectedContent"
    [identityMatcher]="sortMatcher"
    >Сортировать
    <tui-data-list *tuiDataList>
      <tui-opt-group>
        <ng-container>
          <button tuiOption (click)="onSort(item)">
            <tui-svg
              *ngIf="item.asc"
              src="tuiIconSortUp"
              class="icon"
            ></tui-svg>
            <tui-svg
              *ngIf="!item?.asc"
              src="tuiIconSortDown"
              class="icon"
            ></tui-svg>
            {{ item.asc ? "По возрастанию" : "По убыванию" }}
          </button>
        </ng-container>
      </tui-opt-group>
      <tui-opt-group>
        <button *ngFor="let item of sortItems" tuiOption [value]="item">
          {{ item.title }}
        </button>
      </tui-opt-group>
    </tui-data-list></tui-select
  >
  <ng-template #sortSelectedContent let-item>
    {{ item.title }}
  </ng-template>

  <div tuiGroup class="price" [collapsed]="true">
    <div>
      <tui-input-number
        formControlName="priceFrom"
        class="tui-group__inherit-item"
        postfix="руб"
      >
        Цена от
      </tui-input-number>
    </div>
    <div>
      <tui-input-number
        formControlName="priceTo"
        class="tui-group__inherit-item"
        postfix="руб"
      >
        Цена до
      </tui-input-number>
    </div>
  </div>

  <div class="search">
    <tui-input
      formControlName="search"
      [tuiHintContent]="searchHintContent"
      [tuiTextfieldCleaner]="true"
    >
      Поиск
    </tui-input>
    <ng-template #searchHintContent>
      Поиск по названию. <br />
      От 3х символов
    </ng-template>
  </div>
</form>
