import { DOCUMENT } from '@angular/common';
import {
  Component,
  EmbeddedViewRef,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TuiPreviewDialogService } from '@taiga-ui/addon-preview';
import { TuiDropdownPortalService } from '@taiga-ui/cdk';
import { TuiDialogContext } from '@taiga-ui/core';
import {
  PolymorpheusTemplate,
  POLYMORPHEUS_CONTEXT,
} from '@tinkoff/ng-polymorpheus';
import { BehaviorSubject, combineLatest, EMPTY, of, Subscription } from 'rxjs';
import {
  delay,
  finalize,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Item } from 'src/app/models/item';
import { WbField } from 'src/app/models/wb-fields';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { ContextDialog } from './context-dialog';

@Component({
  selector: 'app-item-dialog',
  templateUrl: './item-dialog.component.html',
  styleUrls: ['./item-dialog.component.less'],
})
export class ItemDialogComponent implements OnInit, OnDestroy {
  private wbPortal: EmbeddedViewRef<PolymorpheusTemplate> | null = null;

  @ViewChild('button', { static: true, read: PolymorpheusTemplate })
  button!: PolymorpheusTemplate<any>;
  @ViewChild('preview')
  readonly preview!: TemplateRef<TuiDialogContext<void>>;

  readonly item = this.context.data.item;
  readonly productPhotoUrl = `https://storage.yandexcloud.net/${
    environment.wbProductsBucket
  }/${this.item[WbField.nmId]}-m-1.jpg`;
  readonly photos$ = this.userService.getUser().pipe(
    switchMap((user) =>
      user
        ? this.apiService.getProductPhotos(
            (this.activatedRoute.snapshot.data || {})['sheetName'],
            this.item[WbField.imtId],
            this.userService.getUserAsync()?.authToken
          )
        : of([])
    ),
    map((photos) => [this.context.data.photoUrl, ...photos].filter(Boolean)),
    map((photos) => (photos.length ? photos : null)),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  readonly previewIndex$ = new BehaviorSubject<number>(1);
  readonly previewPhotoUrl$ = combineLatest([
    this.photos$,
    this.previewIndex$,
  ]).pipe(map(([photos, index]) => photos![index]));
  previewDialog$: Subscription | null = null;

  readonly getCategory = (item: Item): string => item[WbField.subjRootName];
  readonly getPrice = (item: Item): number => item[WbField.detailSalePriceU];
  readonly getDescription = (item: Item): string => item[WbField.imtName];
  readonly getWbUrl = (item: Item): string => item[WbField.messageSiteUrl];
  readonly getBrand = (item: Item): string => item[WbField.sellingBrandName];
  readonly getSeason = (item: Item): string => item[WbField.season];
  readonly filterEmpty = (text: string): string =>
    !text || text === 'не указано' ? '' : text;

  readonly getCommentText = (item: Item): string => item[WbField.messageText];
  readonly getCommentUrl = (item: Item): string => item[WbField.messageUrl];
  readonly getCommentDate = (item: Item): number | null =>
    item[WbField.messageDate];
  readonly getRepeats = (item: Item): Item[] => item[WbField.frontRepeatedList];
  readonly getCommentCount = (item: Item): number =>
    item[WbField.messageRepeatCount];

  constructor(
    private readonly apiService: ApiService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, ContextDialog>,
    @Inject(TuiDropdownPortalService)
    private readonly portalService: TuiDropdownPortalService,
    private readonly activatedRoute: ActivatedRoute,
    @Inject(TuiPreviewDialogService)
    private readonly previewDialogService: TuiPreviewDialogService,
    private readonly userService: UserService,
    @Inject(DOCUMENT)
    private readonly document: Document
  ) {}

  ngOnInit() {
    this.wbPortal = this.portalService.addTemplate(this.button.template);
  }

  ngOnDestroy() {
    this.portalService.removeTemplate(this.wbPortal!);
  }

  onPhotoClick(index: number) {
    this.previewIndex$.next(index);

    const bgClick = (e: Event) => {
      const p = this.document.querySelector('tui-preview');

      if (e.target === p) {
        this.previewDialog$?.unsubscribe();
      }
    };

    this.previewDialog$ = this.previewDialogService
      .open(this.preview)
      .pipe(
        startWith(null),
        delay(200),
        tap(() => {
          this.document
            .querySelector('tui-preview')
            ?.addEventListener('click', bgClick);
        }),
        finalize(() => {
          this.document.removeEventListener('click', bgClick);
        })
      )
      .subscribe();
  }

  previewClose() {
    this.previewDialog$?.unsubscribe();
  }
}
