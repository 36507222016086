import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SignInComponent } from './pages/signin/signin.component';
import { SigninPageGuard } from './pages/signin/signin.guard';
import { SignInGuard } from './sign-in.guard';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [SignInGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent,
        data: { sheetName: environment.womanSheet },
      },
      {
        path: 'man',
        component: HomeComponent,
        data: { sheetName: environment.manSheet },
      },
      {
        path: 'other',
        component: HomeComponent,
        data: { sheetName: environment.wbOtherSheet },
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
    ],
  },
  {
    path: 'signin',
    component: SignInComponent,
    canActivate: [SigninPageGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
