import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TuiCurrency } from '@taiga-ui/addon-commerce';
import {
  TuiDestroyService,
  TuiIdentityMatcher,
  tuiIsPresent,
  TuiStringHandler,
} from '@taiga-ui/cdk';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  skip,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { Item } from '../../models/item';
import { LogicField } from '../../models/logic-fields';
import { WbField } from '../../models/wb-fields';
import { FilterCategory } from './logic/category';
import { FilterPriceFrom } from './logic/price-from';
import { FilterPriceTo } from './logic/price-to';
import { FilterSearch } from './logic/search';
import { FilterSort, sortedItems, SortItem } from './logic/sort';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.less'],
  providers: [TuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent implements OnChanges {
  private readonly filters$ = new BehaviorSubject<any[]>([]);

  @Input() set fields(fields: Array<LogicField>) {
    this.fields$.next(fields);
  }

  @Input() set items(items: Item[] | null) {
    this.items$.next(items || []);
  }

  @Output() readonly itemsChanged = new EventEmitter<Item[]>();

  readonly form = new FormGroup<any>({});

  readonly LogicField = LogicField;

  readonly fields$ = new BehaviorSubject<Array<LogicField>>([]);
  readonly items$ = new BehaviorSubject<Array<Item>>([]);
  readonly categories$ = this.items$.pipe(
    map((items) => [
      ...new Set((items || []).map((item) => item[WbField.subjRootName])),
    ])
  );
  readonly sortSelectedItem$ = this.fields$.pipe(
    switchMap(() => this.form.get(LogicField.sort)?.valueChanges || of(null))
  );

  readonly stringify: TuiStringHandler<any> = (item) =>
    `name` in item ? item.name : item.$implicit.name;

  readonly identityMatcher: TuiIdentityMatcher<any> = (hero1, hero2) =>
    hero1.id === hero2.id;

  readonly enabledFilter = (fields: LogicField[], filter: LogicField) =>
    fields.includes(filter);

  readonly sortItem = (item: SortItem | null) => item || sortedItems[0];
  readonly sortMatcher: TuiIdentityMatcher<SortItem | null> = (
    item1: SortItem | null,
    item2: SortItem | null
  ) => item1?.title === item2?.title;

  readonly sortItems = sortedItems;

  readonly currencyRub = TuiCurrency.Ruble;

  constructor(
    private readonly destroy$: TuiDestroyService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    combineLatest([
      this.fields$,
      this.route.queryParamMap.pipe(
        filter((queryParamMap) => !queryParamMap.has('filter')),
        startWith(this.route.snapshot.queryParamMap),
        distinctUntilChanged()
      ),
    ])
      .pipe(filter(tuiIsPresent), takeUntil(this.destroy$))
      .subscribe(([fields, queryParamMap]) => {
        const formValues = JSON.parse(queryParamMap.get('filter') || '{}');

        this.filters$.next(
          fields.map((field) => {
            if (field === LogicField.category) {
              this.form.removeControl(field);
              this.form.addControl(field, new FormControl(formValues[field]));
              return new FilterCategory({} as any);
            }

            if (field === LogicField.sort) {
              this.form.removeControl(field);
              this.form.addControl(
                field,
                new FormControl(formValues[field] || sortedItems[0])
              );
              return new FilterSort();
            }

            if (field === LogicField.search) {
              this.form.removeControl(field);
              this.form.addControl(field, new FormControl(formValues[field]));
              return new FilterSearch();
            }

            if (field === LogicField.priceFrom) {
              this.form.removeControl(field);
              this.form.addControl(field, new FormControl(formValues[field]));
              return new FilterPriceFrom();
            }

            if (field === LogicField.priceTo) {
              this.form.removeControl(field);
              this.form.addControl(field, new FormControl(formValues[field]));
              return new FilterPriceTo();
            }

            this.form.addControl(field, new FormControl(formValues[field]));
            return field;
          })
        );
      });

    combineLatest([this.filters$, this.form.valueChanges, this.items$])
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe(([filters, formValues, items]) => {
        this.router.navigate([], {
          relativeTo: this.route,
          replaceUrl: true,
          queryParams: {
            ...this.route.snapshot.queryParams,
            filter: JSON.stringify(formValues),
          },
        });

        const result = filters.reduce((items, filter) => {
          if (filter instanceof FilterCategory) {
            return filter.filter(items, formValues[LogicField.category]);
          }

          if (filter instanceof FilterSort) {
            return filter.filter(items, formValues[LogicField.sort]);
          }

          if (filter instanceof FilterSearch) {
            return filter.filter(items, formValues[LogicField.search]);
          }

          if (filter instanceof FilterPriceFrom) {
            return filter.filter(items, formValues[LogicField.priceFrom]);
          }

          if (filter instanceof FilterPriceTo) {
            return filter.filter(items, formValues[LogicField.priceTo]);
          }

          return items;
        }, items?.slice());

        this.itemsChanged.emit(result);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['items']) {
      return;
    }
  }

  onSort(selectedItem: SortItem) {
    const item = selectedItem || sortedItems[0];

    this.form.controls[LogicField.sort].setValue({
      ...item,
      asc: !item.asc,
    });
  }
}
