<header class="header">
  <div class="wrapper">
    <p class="description tui-text_body-xl">
      {{ item | tuiMapper : getDescription }}
    </p>
    <h3 class="title">
      {{ item | tuiMapper : getCategory }}
    </h3>
    <tui-money
      decimal="always"
      class="money"
      [value]="item | tuiMapper : getPrice"
    ></tui-money>
  </div>
</header>

<ng-container *ngIf="photos$ | async as photos">
  <h3 class="tui-text_h6">Фото</h3>
  <div class="photos">
    <button
      tuiIconButton
      appearance="flat"
      size="m"
      icon="tuiIconChevronLeftLarge"
      title="Previous"
      (click)="carousel.prev()"
    ></button>
    <tui-carousel #carousel [draggable]="true" [itemsCount]="3">
      <ng-container *ngFor="let photo of photos; let i = index">
        <img
          *tuiItem
          loading="lazy"
          decoding="async"
          [draggable]="false"
          [src]="photo"
          (click)="onPhotoClick(i)"
        />
      </ng-container>
    </tui-carousel>
    <button
      tuiIconButton
      appearance="flat"
      size="m"
      icon="tuiIconChevronRightLarge"
      title="Next"
      (click)="carousel.next()"
    ></button>
  </div>

  <ng-template #preview>
    <tui-preview>
      <tui-preview-pagination
        [length]="photos.length"
        [index]="previewIndex$.value"
        (indexChange)="previewIndex$.next($event)"
      ></tui-preview-pagination>

      <button
        tuiIconButton
        tuiPreviewAction
        icon="tuiIconCloseLarge"
        title="Close"
        (click)="previewClose()"
      ></button>

      <img [src]="previewPhotoUrl$ | async" />
    </tui-preview>
  </ng-template>
</ng-container>

<h3 class="tui-text_h6">Дополнительно</h3>
<table class="tui-table tui-table_size_s">
  <tbody>
    <tr
      *ngIf="item | tuiMapper : getBrand | tuiMapper : filterEmpty as brand"
      class="tui-table__tr tui-table__tr_border_none"
    >
      <td class="tui-table__td">Бренд</td>
      <td class="tui-table__td">
        {{ brand }}
      </td>
    </tr>
    <tr
      *ngIf="item | tuiMapper : getSeason | tuiMapper : filterEmpty as season"
      class="tui-table__tr tui-table__tr_border_none"
    >
      <td class="tui-table__td">Сезон</td>
      <td class="tui-table__td">
        {{ season }}
      </td>
    </tr>
  </tbody>
</table>

<h3 class="tui-text_h6">Упоминания товара</h3>
<table class="tui-table tui-table_size_s table-repeat table">
  <tbody class="comment-tbody">
    <tr class="tui-table__th tui-table__tr_border_none">
      <th class="tui-table__th full-width">Текст сообщения</th>
      <th class="tui-table__th">Открыть</th>
      <th class="tui-table__th">Дата</th>
    </tr>
    <tr
      *ngFor="let repeatItem of item | tuiMapper : getRepeats"
      class="tui-table__tr tui-table__tr_border_none comment-tr"
    >
      <td class="tui-table__td comment-td">
        {{ repeatItem | tuiMapper : getCommentText }}
      </td>
      <td class="tui-table__td comment-td">
        <a
          *ngIf="repeatItem | tuiMapper : getCommentUrl as url"
          class="tg-link"
          [href]="url"
          target="_blank"
          tuiLink
          referrerpolicy="no-referrer"
          rel="noreferrer"
          ><span class="text">Открыть сообщение</span> <img src="assets/tg.svg"
        /></a>
      </td>
      <td
        *tuiLet="repeatItem | tuiMapper : getCommentDate as date"
        class="tui-table__td comment-td"
      >
        <ng-container *ngIf="date">
          {{ date | date : "dd.MM.yyyy" }}
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #button="polymorpheus" polymorpheus>
  <a
    tuiButton
    type="button"
    tuiPreventDefault="touchmove"
    class="portal"
    target="_blank"
    referrerpolicy="no-referrer"
    rel="noreferrer"
    [href]="item | tuiMapper : getWbUrl"
  >
    Открыть в Wildberries
  </a>
</ng-template>
