<div class="tui-container tui-container_adaptive">
  <div class="filters">
    <app-filters
      *ngIf="loaded$ | async"
      [fields]="fields"
      [items]="allItems$ | async"
      (itemsChanged)="onFilteredItems($event)"
    ></app-filters>
  </div>
  <div
    *tuiLet="productsPhotos$ | async as productsPhotos"
    appInfinityScroll
    class="items"
    (scrollingFinished)="onScroll()"
  >
    <ng-container *ngIf="loaded$ | async; else loading">
      <a
        *ngFor="let item of slicedItems$ | async; empty: empty"
        class="item"
        tuiIsland
        size="l"
        [hoverable]="true"
        [routerLink]="[]"
        [relativeTo]="activatedRoute"
        [replaceUrl]="true"
        [queryParams]="item | tuiMapper : productItemQueryParams"
      >
        <div class="tui-island__content">
          <figure class="tui-island__figure">
            <img
              class="product-photo"
              loading="lazy"
              decoding="async"
              alt=""
              [src]="item | tuiMapper : getWbProductPhotoUrl : productsPhotos"
            />
          </figure>
          <div>
            <div class="tui-island__category category">
              {{ item | tuiMapper : getCategory }}
            </div>
            <div class="tui-island__title">
              <ng-container *ngIf="item | tuiMapper : getPrice as money">
                <tui-money [value]="money"></tui-money>
              </ng-container>
            </div>

            <div
              class="tui-island__paragraph"
              [class.tui-space_bottom-6]="item | tuiMapper : getCommentCount"
            >
              {{ item | tuiMapper : getDescription }}
            </div>

            <span
              *ngIf="item | tuiMapper : getCommentCount as commentCount"
              class="comments tui-island__category"
              tuiHintDirection="top"
              [tuiHint]="commentCount | tuiMapper : getCommentHint"
              ><tui-svg src="tuiIconComment"></tui-svg>{{ commentCount }}</span
            >
            <div
              *ngIf="item | tuiMapper : getShoesMaxSize as shoesMaxSize"
              class="max-size"
            >
              до {{ shoesMaxSize }}р.
            </div>
          </div>
        </div>
      </a>
    </ng-container>
    <ng-template #loading>
      <tui-island
        *ngFor="let _ of [1, 2, 3]"
        tuiIsland
        class="item"
        size="l"
        target="_blank"
        referrerpolicy="no-referrer"
        rel="noreferrer"
        [hoverable]="true"
      >
        <div
          class="tui-island__category tui-skeleton tui-skeleton_text tui-skeleton_short"
        ></div>
        <div
          class="tui-island__title tui-skeleton tui-skeleton_text tui-skeleton_short price-skeleton"
        ></div>
        <div class="tui-island__paragraph tui-skeleton tui-skeleton_text"></div>
      </tui-island>
    </ng-template>
    <ng-template #empty>
      <h5 class="tui-text_h5">Ничего не найдено</h5>
    </ng-template>
  </div>
</div>
