<div class="tui-container tui-container_adaptive">
  <div tuiMode="onDark" class="wrapper">
    <a routerLink="/">
      <img src="assets/logo.jpg" alt="" />
      <span class="text tui-text_h5">Вид&nbsp;сверху</span>
    </a>
    <div *tuiLet="activeLink$ | async as activeLink" class="btns">
      <ng-container *ngFor="let btn of btns$ | async">
        <button
          tuiButton
          class="btn btn-large"
          type="button"
          size="l"
          [appearance]="
            (btn.link | tuiMapper : activated$ | async) ? 'secondary' : 'flat'
          "
          [routerLink]="btn.link"
        >
          {{ btn.title }}
        </button>
        <button
          tuiButton
          class="btn btn-small"
          type="button"
          size="m"
          [appearance]="
            (btn.link | tuiMapper : activated$ | async) ? 'secondary' : 'flat'
          "
          [routerLink]="btn.link"
        >
          {{ btn.title }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
