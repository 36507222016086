import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  readonly activeLink$ = this.router.events.pipe(
    filter((event: any) => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.urlAfterRedirects.split('?').shift()),
    startWith('/')
  );

  readonly activated$ = (url: string): Observable<boolean> =>
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      startWith('/'),
      map(() =>
        this.router.isActive(url, {
          paths: 'exact',
          fragment: 'ignored',
          matrixParams: 'ignored',
          queryParams: 'ignored',
        })
      )
    );
  readonly btns$ = this.userService.getUser().pipe(
    map((user) => {
      const btns = [
        {
          title: 'Женская одежда и обувь',
          link: '/',
        },
        {
          title: 'Мужская одежда и обувь',
          link: '/man',
        },
        { title: 'Другое', link: '/other' },
      ];
      const profileBtn = {
        title: user?.first_name || 'Профиль',
        link: '/profile',
      };
      const signInBtn = { title: 'Войти', link: '/signin', active: false };

      return btns.concat(user ? profileBtn : signInBtn);
    })
  );

  constructor(
    private readonly router: Router,
    private readonly userService: UserService
  ) {}
}
