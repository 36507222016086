import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiLetModule, TuiMapperPipeModule } from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiGroupModule,
  TuiHintModule,
  TuiPrimitiveTextfieldModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import {
  TuiDataListWrapperModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiMultiSelectModule,
  TuiSelectModule,
} from '@taiga-ui/kit';
import { FiltersComponent } from './filters.component';

@NgModule({
  declarations: [FiltersComponent],
  exports: [FiltersComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TuiMultiSelectModule,
    TuiTextfieldControllerModule,
    TuiDataListWrapperModule,
    TuiDataListModule,
    TuiSelectModule,
    TuiGroupModule,
    TuiInputModule,
    TuiInputNumberModule,
    TuiPrimitiveTextfieldModule,
    TuiLetModule,
    TuiSvgModule,
    TuiMapperPipeModule,
    TuiHintModule,
  ],
})
export class FiltersModule {}
