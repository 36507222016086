import { WbField } from 'src/app/models/wb-fields';
import { Item } from '../../../models/item';

export class FilterSearch {
  filter(items: Item[], searchQuery?: string): Item[] {
    if (!searchQuery?.length || searchQuery.length < 3) {
      return items;
    }

    const queries = searchQuery.split(' ');
    const regexp = new RegExp(queries.map((q) => `(${q})`).join('|'), 'ig');

    return items.filter(
      (item) =>
        (item[WbField.imtName].match(regexp) || []).length >= queries.length
    );
  }
}
