import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  TuiButtonModule,
  TuiLoaderModule,
  TuiNotificationModule,
} from '@taiga-ui/core';
import { SignInComponent } from './signin.component';

@NgModule({
  declarations: [SignInComponent],
  imports: [
    CommonModule,
    TuiNotificationModule,
    TuiButtonModule,
    TuiLoaderModule,
  ],
  exports: [SignInComponent],
})
export class SignInModule {}
