import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TuiMoneyModule } from '@taiga-ui/addon-commerce';
import { TuiForModule, TuiLetModule, TuiMapperPipeModule } from '@taiga-ui/cdk';
import { TuiButtonModule, TuiHintModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiBadgeModule, TuiIslandModule } from '@taiga-ui/kit';
import { FiltersModule } from 'src/app/components/filters/filters.module';
import { ItemDialogModule } from 'src/app/components/item-dialog/item-dialog.module';
import { InfinityScrollDirective } from 'src/app/directives/infinity-scroll.directive';
import { HomeComponent } from './home.component';

@NgModule({
  declarations: [HomeComponent, InfinityScrollDirective],
  imports: [
    CommonModule,
    RouterModule,

    TuiIslandModule,
    TuiMoneyModule,
    TuiButtonModule,
    TuiSvgModule,
    TuiHintModule,
    TuiMapperPipeModule,
    TuiLetModule,
    TuiBadgeModule,

    FiltersModule,
    TuiForModule,
    ItemDialogModule,
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
