import { WbField } from 'src/app/models/wb-fields';
import { Item } from '../../../models/item';

export class FilterPriceFrom {
  filter(items: Item[], priceFrom?: string | number): Item[] {
    if (!priceFrom) {
      return items;
    }

    return items.filter((item) => item[WbField.detailSalePriceU] >= priceFrom);
  }
}
