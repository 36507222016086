import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class SigninPageGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly userService: UserService
  ) {}

  canActivate(): Observable<UrlTree | boolean> {
    return combineLatest([
      this.userService.getUser(),
      this.userService.isUserLoaded$,
    ]).pipe(
      filter(([_, isUserLoaded]) => isUserLoaded),
      map(([user]) => (user ? this.router.createUrlTree(['']) : true))
    );
  }
}
