import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TuiLetModule, TuiMapperPipeModule } from '@taiga-ui/cdk';
import { TuiButtonModule } from '@taiga-ui/core';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    TuiButtonModule,
    TuiMapperPipeModule,
    TuiLetModule,
  ],
})
export class HeaderModule {}
